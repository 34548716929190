<template>
  <div class="convenience">
    <searchForm :form.sync="form" @search="searchEvent"></searchForm>

    <div class="table-container">
      <el-table
        :data="tableData"
        :height="tableHeight"
        style="width: 100%"
        border
        @sort-change="sort_post_change"
        @selection-change="selectedArr = $event"
        :row-class-name="tableRowClassName"
      >
        <el-table-column type="selection" align="center" fixed="left" width="55"></el-table-column>

        <el-table-column
          prop="name"
          align="center"
          header-align="center"
          show-overflow-tooltip
          min-width="165"
          label="健身器材名称"
        >
          <template slot-scope="scope">
            <div style="display: flex; align-items: center;">
              <img :src="scope.row.pictureUrl" style="height:50px; width: 50px; float:left" alt />
              <div>{{scope.row.name}}</div>
            </div>
          </template>
        </el-table-column>

        <el-table-column
          prop="sort"
          sortable="custom"
          align="center"
          min-width="115"
          label="分类顺序"
        ></el-table-column>

        <el-table-column prop="createBy" min-width="148" align="center" label="发布者"></el-table-column>

        <el-table-column prop="createTime" align="center" min-width="168" label="发布时间"></el-table-column>
      </el-table>
    </div>
    <div class="page-content">
      <div>
        <el-button
          class="default-button"
          v-if="buttonAuth.includes('fitnessFacilities:deviceTypeManger:add')"
          @click="changeAddDialog(true)"
        >{{$t('add')}}</el-button>
        <el-button
          class="default-button"
          v-if="buttonAuth.includes('fitnessFacilities:deviceTypeManger:edit')"
          :disabled="selectedArr.length !== 1"
          @click="changeAddDialog(true, true, selectedArr)"
        >{{$t('edit')}}</el-button>
        <el-button
          class="default-button"
          v-if="buttonAuth.includes('fitnessFacilities:deviceTypeManger:remove')"
          :disabled="selectedArr.length < 1"
          @click="deleteItem"
        >{{$t('delete')}}</el-button>
      </div>
      <!-- </div>
      <div class="page-container">-->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="form.pageNum"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="form.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="form.total"
      ></el-pagination>
    </div>
    <!-- 添加/修改弹窗 -->
    <add-dialog
      ref="addDialog"
      :Language="Language"
      :show.sync="addDialog.visible"
      :isEdit="addDialog.isEdit"
      @closeDialog="changeAddDialog"
    ></add-dialog>
  </div>
</template>
<script>
// import IndexClass from "./indexClass";
import mixin from "@/mixins/index";
import apis from "@/apis";
import { mapState } from "vuex";
import addDialog from "./dialog/addDialog.vue";
// import confirmDialog from "@/components/confirmDialog.vue";
import pageClass from "@/unit/pageClass";
import createDeleteMixin from "@/mixins/createDeleteMixin.js";
import searchForm from "../components/searchForm.vue";

class IndexClass extends pageClass {
  constructor(type) {
    super();
    if (type === "form") {
      this.categoryId = "";
      this.searchValue = "";
    }
  }
}

export default {
  mixins: [mixin, createDeleteMixin(apis.fitnessDeviceType)],
  components: { addDialog, searchForm },
  data() {
    return {
      form: new IndexClass("form"), // 实例化一个表单的变量
      status: "",
      tableData: [],
      category: [], // tab栏
      addDialog: {
        visible: false,
        isEdit: false,
      },
    };
  },
  mounted() {
    // this.$http.get(apis.contentCategorygetIdAndName).then((res) => {
    //   if (res.data.code === 0) {
    //     this.category = res.data.data;
    //     this.form.categoryId = this.category[0].id;
    //     this.searchEvent();
    //   }
    // });
  },
  activated() {
    // this.$http.get(apis.contentCategorygetIdAndName).then((res) => {
    //   if (res.data.code === 0) {
    //     this.category = res.data.data;
    //   }
    // });
  },
  computed: {
    ...mapState(["Language", "DROPDOWNBOX", "TOKEN", "venueId"]),
  },
  methods: {
    // 查询
    searchData() {
      const formData = { ...this.form };
      this.$http.post(apis.fitnessDeviceTypeList, formData).then((res) => {
        // console.log(res.data.rows);
        if (res.data.code === 0) {
          this.tableData = res.data.rows;
          this.form.total = res.data.total;
        }
      });
    },

    // 新增修改
    changeAddDialog(dialogStatus, isEdit = false, data) {
      if (dialogStatus) {
        this.addDialog.isEdit = isEdit;
        if (isEdit) {
          // 调用vuex里面的获取数据的业务，如果要在这里写接口，需要改成Promise
          this.$refs["addDialog"].getDataQuery(data);
        }
      } else {
        this.searchData();
      }
      this.$nextTick(() => {
        this.addDialog.visible = dialogStatus;
        console.log(this.addDialog.visible);
      });
    },

    /**
     * @function 当确认弹窗点击确定的时候触发
     */
    sureConfirmDialog() {
      // 如果一个页面有多个弹窗，视情况决定用if语句还是二元表达式对api进行判断
      if (this.confirmDialog.type === 9 || this.confirmDialog.type === 17) {
        this.modelHangOn();
      } else if (
        this.confirmDialog.type === 18
        || this.confirmDialog.type === 19
      ) {
        this.topHandle();
      }
    },
  },
};
</script>
<style lang="less" scoped>
@import "~@/assets/styles/mixin.scoped.less";
</style>
<style lang="less">
@import "~@/assets/styles/mixin.less";
.convenience {
  .page-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
  }
  .el-tabs__active-bar {
    display: none;
  }
}
</style>
