<template>
  <div class="search-container">
    <el-form ref="form" :model="mForm">
      <el-row>
        <el-col :span="6">
          <el-form-item prop="searchValue">
            <el-input clearable v-model="mForm.searchValue" placeholder="请输入关键字"></el-input>
          </el-form-item>
        </el-col>
        <el-col :offset="12" :span="6" class="text-right">
          <el-button type="primary" @click="search" class="search-button">{{$t('search')}}</el-button>
          <el-button type="primary" @click="cleanForm" class="clean-button">{{$t('clean')}}</el-button>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
/*
props:form 双向
emit: search 点击搜索跟点击清空时触发 参数 搜索的条件
*/

import { extend } from "lodash";

export default {
  props: {
    /**
     * 任意的对象，双向
     */
    form: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  data() {
    return {
      mForm: {
        searchValue: "",
      },
    };
  },
  methods: {
    search() {
      const form = extend({}, this.form, this.mForm);
      this.$emit("update:form", form);
      this.$emit("search", form);
    },

    cleanForm() {
      this.mForm = {
        searchValue: "",
      };
      this.search();
    },
  },
};
</script>

<style>
</style>
