<template>
  <div class="dialog-container convenienceDialog">
    <el-dialog
      :title="`${isEdit ? '修改' : '新增'}`"
      :visible.sync="show"
      @opened="openDialog"
      :before-close="closeDialog"
      top="10px"
      center
      :close-on-click-modal="false"
      width="650px"
    >
      <el-form ref="form" :model="form" label-width="100px" :rules="rules">
        <el-row>
          <el-col :span="24">
            <el-form-item label="分类名称" prop="name">
              <el-input v-model="form.name" maxlength="30"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="分类顺序" prop="sort">
              <el-input v-model="form.sort" type="number" oninput="if(value>10000)value=10000"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item label="展示图片" prop="pictureUrl">
              <el-upload
                class="upload"
                v-if="showPic"
                :show-file-list="false"
                :with-credentials="true"
                :action="apis.ImageUpload"
                :httpRequest="uploadHttpDefault"
                :headers="{
                      token: TOKEN
                  }"
                name="file"
                accept=".png, .jpg"
                :limit="1"
                :on-success="uploadSuccess1"
                :on-error="uploadError"
                ref="upload"
              >
                <img
                  class="image-upload"
                  src="~@/assets/images/upload_image.png"
                  v-if="form.pictureUrl === ''"
                  alt
                  srcset
                />
                <img
                  v-if="form.pictureUrl"
                  :src="form.pictureUrl"
                  alt
                  srcset
                  width="50"
                  height="50"
                />
                <div class="upload-images-tip">建议尺寸38x44px</div>
              </el-upload>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <template slot="footer">
        <el-button class="footer-confirm" @click="sureEvent">{{$t('sure')}}</el-button>
        <el-button class="footer-cancel" @click="closeDialog">{{$t('cancel')}}</el-button>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { mapState } from "vuex";
import mixin from "@/mixins/dialogMixin";
// import AddDialogClass from "./addDialogClass";
import apis from "@/apis";

// import previewImage from "./preview.vue";
// import dialogMap from "@/components/dialogMap.vue";

// class AddDialogClass {
//   constructor() {
//     this.pictureUrl = "";
//   }
// }

function getForm() {
  return {
    name: "",
    pictureUrl: "",
    sort: "",
  };
}

function getRules() {
  return {
    name: [
      {
        required: true,
        message: "请输入分类名称",
        trigger: "change",
      },
    ],
    pictureUrl: [
      {
        required: true,
        message: "请选择图片",
        trigger: "change",
      },
    ],
    sort: [
      {
        required: true,
        message: "请输入分类顺序",
        trigger: "change",
      },
    ],
  };
}

export default {
  mixins: [mixin],
  components: {
    // quillEditor,
    // newMap,
    // previewImage,
    // dialogMap,
  },
  props: {
    show: {
      required: true,
      type: Boolean,
    },
    isEdit: {
      required: true,
      type: Boolean,
    },
    Language: {
      required: false,
      type: Number,
      default: 0,
    },
  },
  // mounted() {
  //   console.log("DROPDOWNBOX.VENUE_SERVICE", this.DROPDOWNBOX.WEEK)
  // },
  data() {
    return {
      categoryData: [], // 分类的下拉框选项
      baseImage: "",
      form: getForm(), // 实例化一个表单的变量
      rules: getRules(), // 实例化一个表单的规则
      apis,
      showMap: true,
      isRequired: true,
      showPic: true,

      previewImage: {
        visible: false,
        src: "",
      },
    };
  },
  computed: {
    ...mapState(["DROPDOWNBOX", "TOKEN", "venueList"]),
  },
  methods: {
    /**
     * @function 关闭弹窗
     * @description 先初始化，然后传值到组件外部进行关闭
     **/
    async closeDialog() {
      await this.init();

      this.$emit("closeDialog", false);
    },

    /**
     * @function 初始化弹窗
     */
    init() {
      this.$refs.form.resetFields();
      // this.$refs.forms.resetFields();
      this.form = getForm();
      this.$refs.upload.clearFiles();
      console.log("我初始化了");
    },

    /**
     * @function 点击确定按钮，先执行业务，完了调关闭方法，不要写两次关闭方法
     */
    sureEvent() {
      // 这里写业务需求
      this.editfirst = false;
      // this.$refs.forms.validate((valida) => {
      // if (valida) {
      this.$refs.form.validate((valid) => {
        if (valid) {
          const formData = { ...this.form };
          // formData.latitude = formData.point.lat;
          // formData.longitude = formData.point.lng;
          if (this.isEdit) {
            this.$http.post(apis.fitnessDeviceTypeUpdate, formData).then((res) => {
              if (res.data.code === 0) {
                this.closeDialog();
              }
            });
          } else {
            // 新增
            this.$http.post(apis.fitnessDeviceTypeSave, formData).then((res) => {
              if (res.data.code === 0) {
                this.closeDialog();
              }
            });
          }
        } else {
          this.form.status = "first";
        }
      });
      // }
      // });
    },
    /**
     * @function 回显的时候获取数据
     * @param {Object} data 要传输给后端的数据
     */
    getDataQuery(data) {
      // 在这里获取回显的数据
      this.$http.get(`${apis.fitnessDeviceTypeGet}/${data[0].id}`).then((res) => {
        if (res.data.code === 0) {
          // console.log(res.data.data);
          this.form = {
            ...res.data.data,
          };
        }
      });
    },
    // uploadImageBase(file) {
    //   this.$http
    //     .post(apis.Base64ImageUpload, { Base64Data: file })
    //     .then((res) => {
    //       if (res.data.code === 200) {
    //         console.log("uploadImageBase", res);
    //         this.form.venueCircleLogo = res.data.data.path;
    //       }
    //     });
    // },

    // 封面图片/地图图标失败
    uploadError(res) {
      console.log(res);
    },
    // 地图图标成功
    uploadSuccess1(res) {
      this.form.pictureUrl = res.data.url;
      // this.form.mapIcon = res.data.path;
      // this.showPic = false;
      this.$nextTick(() => {
        this.showPic = true;
      });
      this.$refs.form.validate("pictureUrl");
    },
  },
};
</script>
<style lang="less" scoped>
.image-upload {
  width: 50px;
  height: 50px;
}
.upload-images-tip {
  color: rgba(168, 168, 168, 1);
  font-size: 12px;
  margin-top: -15px;
}
.bmap-wrap {
  width: 100%;
}
.image-wrap {
  width: 50px;
  height: 50px;
  margin-right: 10px;
  position: relative;
  float: left;
  .images-close {
    position: absolute;
    right: -7px;
    top: -7px;
    width: 14px;
    height: 14px;
    cursor: pointer;
  }
}
</style>
<style lang="less">
@import "~@/assets/styles/mixin.less";
.service-wrapper {
  .el-checkbox {
    min-width: 80px !important;
  }
}
.convenienceDialog {
  .ql-editor {
    .ql-editor {
      height: 188px;
    }
  }
  .edit_container {
    .ql-snow {
      .ql-tooltip {
        left: 10px !important;
      }
    }
  }
}
</style>
